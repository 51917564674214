import { render, staticRenderFns } from "./AddSlideMenu.vue?vue&type=template&id=a3b1d2ae&scoped=true"
import script from "./AddSlideMenu.vue?vue&type=script&lang=ts"
export * from "./AddSlideMenu.vue?vue&type=script&lang=ts"
import style0 from "./AddSlideMenu.vue?vue&type=style&index=0&id=a3b1d2ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3b1d2ae",
  null
  
)

export default component.exports