
import Vue from 'vue';
import { OutputSlide } from '@/shared/legacy/classes';
import { LogoWithSize } from '@/shared/types';

import { Models, Services } from '@/injectables/tokens';
import {
  SlideModelContract,
  CreateSlideOptions,
  AgencyServiceContract,
  UploadServiceContract,
  OutputModelContract,
} from '@/injectables';

import { SlideLibrary } from '@/widgets/common/slide-library';

import SlidePreview from '@/components/Output/Slide.vue';

export default Vue.extend({
  name: 'AddSlideMenu',

  useInjectable: [Models.Slide, Models.Output, Services.Agency, Services.Upload],

  components: { SlideLibrary, SlidePreview },

  props: {
    isManageLayouts: {
      type: Boolean,
      default: false,
    },
    isSetSlideType: {
      type: Boolean,
      default: false,
    },
    isSmallerScreen: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isAgencyAdmin: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      default: '',
    },
    agencyId: {
      type: String,
      default: '',
    },
    isSkipRebuildSlide: {
      type: Boolean,
      default: false,
    },
    colorScheme: {
      type: Object,
      default: () => ({}),
    },
  },

  data: (): {
    showLibraryDialog: boolean;
    openNewSlideMenu: boolean;
  } => ({
    showLibraryDialog: false,
    openNewSlideMenu: false,
  }),

  computed: {
    singleColumn(): boolean {
      return this.layoutBlanks.length < 2;
    },
    layoutBlanks(): OutputSlide[] {
      return this.$store.state.output.activeOutput.blanks || [];
    },
    selectedLayout(): string {
      return this.$store.state.output.activeOutput?.layout || '';
    },
    slideColors(): string {
      return this.$store.state.output.activeOutput?.slideColors;
    },
    currentAgency(): string {
      return this.agencyId || this.$store.state.output.activeOutput?.agency;
    },
  },

  methods: {
    openSlideLibrary(): void {
      this.showLibraryDialog = true;
      this.openNewSlideMenu = false;
    },
    openNewSlideSelector(): void {
      this.openNewSlideMenu = true;
    },
    async createNewSlide({ slides = undefined, fromLayout = false, fromLibrary = false }): Promise<void> {
      const slideEntity: SlideModelContract = this.slideEntity;
      const forcedSlideArray = this.$store.getters['output/forceArray'](slides);

      const newSlideOrder = await this.$store.dispatch('output/newSlideOrder', forcedSlideArray.length);

      const activeSlide = this.$store.getters['output/activeSlide'];

      const slideGroup = (this[Models.Output] as OutputModelContract).getSlideGroup(activeSlide);

      const agencyLogo = await this.getLogo(this.currentAgency);

      const options: CreateSlideOptions = {
        setFooter: this.selectedLayout?.name?.includes('today') && !fromLayout && !fromLibrary,
        fromLayout,
        fromLibrary,
        colors: this.$store.getters['output/slideColors'],
        productId: this.productId,
        clientName: this.$store.getters['client/activeClient']?.name || 'Client Name',
        agencyLogo,
        selectedLayout: this.selectedLayout,
        defaultSlide: this.isManageLayouts,
        slideGroup,
      };

      const updatedSlides = forcedSlideArray.map((slide, i) => {
        return slideEntity.createNewSlide({
          slide,
          index: newSlideOrder + i,
          options: {
            ...options,
            setFooter: options.setFooter && !slide,
          },
        });
      });

      if (!this.isSetSlideType) {
        this.$store
          .dispatch('output/addSlide', updatedSlides)
          .then(() => (this.openNewSlideMenu = false))
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error('OutputEditToolbar/createNewSlide', err);
          });
      } else {
        this.$emit('update-slide-type', updatedSlides[0]);
        this.openNewSlideMenu = false;
      }
    },

    async getLogo(agencyId): Promise<LogoWithSize> {
      const { isErr, unwrap } = await (this.agencyService as AgencyServiceContract).getLogo(agencyId);

      if (isErr()) {
        return null;
      }
      const imageUrl = unwrap();
      const { isErr: isErrInfo, unwrap: unwrapInfo } = await (this.uploadService as UploadServiceContract).getImageInfo(
        imageUrl,
      );
      if (isErrInfo()) return null;
      const { width, height } = unwrapInfo();

      return { logo: imageUrl, avtr: imageUrl, logoWidth: width, logoHeight: height };
    },
  },
});
